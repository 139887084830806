import React, {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../Login";

const AutoLogout = () => {
    const navigate = useNavigate();
    const timeoutRef = useRef(null);
    const auth = useAuth()
    const CHECK_INTERVAL = 1000;
    const TIMEOUT = 30 * 60 * 1000;

    const handleLogout = () => {
        clearTimeout(timeoutRef.current);
        auth.signout(() => navigate("/login"));
    };

    const resetLocalTimer = () => {
        localStorage.setItem('lastActivity', Date.now());
    };

    useEffect(() => {
        localStorage.setItem('lastActivity', Date.now());
        const resetTimer = () => resetLocalTimer();

        const events = ['mousemove', 'keypress', 'scroll', 'click'];
        events.forEach(event => window.addEventListener(event, resetTimer));


        const syncActivity = () => {
            const lastActivity = localStorage.getItem('lastActivity');
            if (!lastActivity || Date.now() - lastActivity > TIMEOUT) {
                handleLogout();
            }
        };

        const syncInterval = setInterval(syncActivity, CHECK_INTERVAL);

        resetLocalTimer();

        return () => {
            events.forEach(event => window.removeEventListener(event, resetTimer));
            clearTimeout(timeoutRef.current);
            clearInterval(syncInterval);
        };
    }, []);

    return null;
};

export default AutoLogout;